@import "../variables.module";

.lists-container {
  &__mini {
    min-height: 88px;
  }
}

.svg-center {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 30px;
  height: 30px;
}

.carouselBtnPrev,
.carouselBtnNext {
  position: absolute;
  top: 50%;
  font-size: 2rem;
  background: transparent;
  border: none;
  cursor: pointer;
  z-index: 2;
}

.carouselBtnPrev {
  left: 1rem;
}

.carouselBtnNext {
  right: 1rem;
}

.list-container {
  width: 100%;
  position: relative;

  &.popular-format {
    .heading-item {
      display: none;
    }

    padding-top: 5px;

    .slots-list {
      gap: 10px;
      grid-template-columns: repeat(8, 1fr);

      &.mobile-one {
        grid-template-columns: repeat(4, 1fr);
      }

      &.mobile-two {
        grid-template-columns: repeat(4, 1fr);
      }

      &.mobile-three {
        grid-template-columns: repeat(4, 1fr);
      }

      &.mobile-four {
        grid-template-columns: repeat(3, 1fr);
      }
    }
  }

  &.mobile-one {
    .quick-link-content {
      border-radius: $radius;
      overflow: hidden;
      // width: calc(100% / 6 - 20px);
      flex: 0 1 calc((100% / 3) - 14px);
    }
  }

  &.mobile-two {
    .quick-link-content {
      border-radius: $radius;
      overflow: hidden;
      // width: calc(100% / 6 - 20px);
      flex: 0 1 calc((100% / 2) - 10px);
    }
  }

  @media (max-width: $breakPointOne) {
    .quick-link-content {
      border-radius: $radius;
      overflow: hidden;
      // width: calc(100% / 6 - 20px);
      // flex: 0 1 calc((100% / 3) - 14px);
    }
  }

  @media (max-width: $breakPointTwo) {
    .quick-link-content {
      border-radius: $radius;
      overflow: hidden;
      // width: calc(100% / 6 - 20px);
      // flex: 0 1 calc((100% / 2) - 10px);
    }
  }

  .heading-item {
    font-size: 15px;
    margin-bottom: 25px;
    display: flex;
    align-items: center;
    gap: 8px;

    &.clickable {
      cursor: pointer;
      width: fit-content;
    }

    &.slot-hourglass-icon {
      svg {
        margin-right: -2px;
        fill: #7179a5;

        path {
          width: 18px;
          height: 18px;
          fill: #7179a5;
        }
      }
    }
  }

  .slots-list {
    display: grid;
    gap: 10px;
    // flex-wrap: wrap;
    grid-template-columns: repeat(8, 1fr);
    // @media screen and (max-width:1200px) {
    //     grid-template-columns: repeat(5, 1fr);
    // }

    // @media screen and (max-width:469px) {
    //     grid-template-columns: repeat(1, 1fr);
    // }

    &.mobile-one {
      grid-template-columns: repeat(6, 1fr);

      &.popular-format {
        .heading-item {
          display: none;
        }

        padding-top: 5px;

        .slots-list {
          gap: 10px;
          grid-template-columns: repeat(8, 1fr);
        }
      }
    }

    &.mobile-two {
      grid-template-columns: repeat(5, 1fr);
    }

    &.mobile-three {
      grid-template-columns: repeat(4, 1fr);
    }

    &.mobile-four {
      grid-template-columns: repeat(3, 1fr);
    }

    @media (max-width: $breakPointOne) {
      grid-template-columns: repeat(4, 1fr);
      gap: 5px;
    }

    @media (max-width: $breakPointTwo) {
      grid-template-columns: repeat(3, 1fr);
    }

    @media (max-width: $breakPointThree) {
      grid-template-columns: repeat(2, 1fr);
    }

    &.search-modal {
      grid-template-columns: repeat(5, 1fr);

      &.mobile-one {
        grid-template-columns: repeat(3, 1fr);
      }

      &.mobile-two {
        grid-template-columns: repeat(2, 1fr);
      }

      &.mobile-three {
        grid-template-columns: repeat(2, 1fr);
      }

      &.mobile-four {
        grid-template-columns: repeat(2, 1fr);
      }

      @media (max-width: $breakPointOne) {
        grid-template-columns: repeat(4, 1fr);
      }

      @media (max-width: $breakPointTwo) {
        grid-template-columns: repeat(3, 1fr);
      }

      @media (max-width: $breakPointThree) {
        grid-template-columns: repeat(2, 1fr);
      }
    }
  }

  .game-coming-soon {
    display: contents;

    .quick-link-content {
      opacity: 0.5;
      cursor: auto;
      pointer-events: none;
      position: relative;

      &.mobile-one {
        &::before {
          font-size: 20px;
        }
      }

      &::before {
        content: "Coming Soon";
        position: absolute;
        display: block;
        z-index: 99;
        font-size: 47px;
        text-transform: uppercase;
        text-align: center;
        font-weight: 800;
        line-height: 1.1;
        padding: 0px 25px;
        bottom: 0px;
        background: rgba(0, 0, 0, 0.6);
        width: calc(100% - 50px);
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: center;

        @media screen and (min-width: 1304px) {
          font-size: 30px;
          // bottom: 15px;
        }

        // &.mobile-one {
        //   font-size: 23px;
        // }
        @media screen and (max-width: 489px) {
          font-size: 24px;
          // bottom: 15px;
        }
      }
    }
  }

  .carousel-slots-list {
    // display: flex;
    flex-wrap: nowrap;
    overflow: hidden;
    transition: transform 0.3s ease-in-out;
    position: relative;
    border: 1px solid pink;
    height: 150px;

    .carousel-slots-list-container {
      position: absolute;
      height: 150px;
      width: 500px;
      border: 1px solid #fff;

      .quick-link-content {
        width: auto;
        height: 150px;
        display: inline-block;
      }
    }

    // flex: 0 1 calc((100% / 6) - 17px);
  }

  @media screen and (min-width: 1200px) {
    max-width: 1200px;
  }
}

.next-prev-container {
  position: absolute;
  display: flex;
  gap: 10px;
  right: 0;
  top: -8px;

  .arrow-icon {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 30px;
    height: 30px;
    border-radius: 6px;
    border: 1px solid rgba(124, 131, 177, 0.2);
    background: linear-gradient(
      180deg,
      rgba(124, 131, 177, 0.2) 0%,
      rgba(94, 103, 158, 0.2) 100%
    );

    cursor: pointer;
    transition:
      opacity 0.1s,
      border 0.1s,
      background 0.1s;
    &.deactive {
      cursor: default;
      opacity: 0.5;
    }

    &:not(.deactive):hover {
      border: 1px solid rgba(124, 131, 177, 0.4);
      background: linear-gradient(
        180deg,
        rgba(124, 131, 177, 0.4) 0%,
        rgba(94, 103, 158, 0.4) 100%
      );
    }
  }
}

.provider-link-content {
  border-radius: $radius;
  overflow: hidden;
  // width: calc(100% / 6 - 20px);
  flex: 0 1 calc((100% / 6) - 17px);
  // border: 1px solid #fff;
  height: 60px;
  display: block;
  position: relative;
  box-sizing: border-box;
  background-color: #171b34;
  // padding: 10px;
  // width: 100px;
  display: flex;
  justify-content: center;
  align-items: center;

  transition:
    transform 300ms $easeInOut3,
    box-shadow 300ms $easeInOut3;

  .img-container {
    position: relative;
    width: 70%;
    height: 75%;
    max-width: 90px;
  }

  &:hover {
    // transform: translateY(-7px);

    transform: translate(0px, -3px);
    cursor: pointer;
    -webkit-box-shadow: 0px 4px 6px 1px rgb(0 0 0 / 15%);
    box-shadow: 0px 4px 6px 1px rgb(0 0 0 / 15%);
  }
}

.inhouse-game-icon {
  aspect-ratio: $card-ar;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  opacity: 0;
  z-index: 1;
  position: absolute;
  height: 100%;
  width: 100%;
  background: rgba(73, 131, 181, 0.85);
  background: linear-gradient(
    149deg,
    rgba(73, 131, 181, 0.65) -1.28%,
    rgba(5, 42, 72, 0.65) 100%
  );

  &:hover {
    opacity: 1;
  }

  p {
    font-size: 20px;
    font-style: normal;
    font-weight: 700;
    line-height: 24px;
    display: -webkit-box;
    /* max-width: 90%; */
    -webkit-line-clamp: 3;
    line-clamp: 3;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-align: center;
    opacity: 1;
    padding: 0px 10px;
    position: absolute;
    margin-top: 20px;

    @media screen and (max-width: 416px) {
      font-size: 18px;
    }
  }

  span {
    font-size: 13px;
    font-style: normal;
    line-height: normal;
    position: absolute;
    bottom: 20px;
  }

  svg {
    @extend .svg-center;
  }
}

.slots-list > div {
  display: grid;
}

.translate-left {
  transform: translateX(-60px) !important;

  &:hover {
    transform: translate(-60px, -3px) !important;
  }
}

.quick-link-content {
  border-radius: $radius;
  overflow: hidden;
  // flex: 0 1 calc(100% / 12 - 10px * 11 / 12);

  position: relative;
  box-sizing: border-box;
  background-color: #151a33;
  aspect-ratio: 0.75;

  &.last-slot-slider {
    opacity: 0.6;
    pointer-events: none;
    mask-image: linear-gradient(
      to right,
      rgba(0, 0, 0, 1) 0%,
      rgba(0, 0, 0, 0) 80%
    );

    &--first {
      @extend .last-slot-slider;
      mask-image: linear-gradient(
        to left,
        rgba(0, 0, 0, 1) 0%,
        rgba(0, 0, 0, 0) 80%
      );
    }
  }

  img {
    opacity: 1;
  }

  transition:
    transform 300ms $easeInOut3,
    box-shadow 300ms $easeInOut3;

  &:hover {
    transform: translate(0px, -3px);
    cursor: pointer;
    -webkit-box-shadow: 0px 4px 6px 1px rgb(0 0 0 / 15%);
    box-shadow: 0px 4px 6px 1px rgb(0 0 0 / 15%);
    // background: rgba(73, 131, 181, 0.85);

    .slot-image {
      opacity: 1;
    }

    .game-details-display {
      opacity: 0.2;
    }
  }

  .slot-image {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    opacity: 0;
    z-index: 3;
    position: absolute;
    height: 100%;
    width: 100%;
    pointer-events: none;
    background: rgba(73, 131, 181, 0.85);
    background: linear-gradient(
      149deg,
      rgba(73, 131, 181, 0.65) -1.28%,
      rgba(5, 42, 72, 0.65) 100%
    );

    p {
      font-size: 20px;
      font-style: normal;
      font-weight: 700;
      line-height: 24px;
      display: -webkit-box;
      /* max-width: 90%; */
      -webkit-line-clamp: 3;
      line-clamp: 3;
      -webkit-box-orient: vertical;
      overflow: hidden;
      text-align: center;
      opacity: 1;
      padding: 0px 10px;
      // position: absolute;
      margin-top: 20px;
    }

    &.mobile-three {
      font-size: 16px;
    }

    @media screen and (max-width: 416px) {
      font-size: 16px;
    }

    span {
      font-size: 13px;
      font-style: normal;
      // font-weight: 500;
      line-height: normal;
      position: absolute;
      bottom: 20px;
    }

    svg {
      // width: 25px;

      @extend .svg-center;
    }
  }

  .more-info-button {
    position: absolute;
    right: 10px;
    top: 10px;
    transition: opacity 500ms $easeInOut3;
    opacity: 0;

    svg {
      fill: $fontColorTwo;
      transform: rotate(180deg);
    }
  }

  .more-info-content {
    // display: none;
    background-color: $secondaryBackgroundColor;
    height: 100px;
    position: absolute;
    bottom: 0;
    padding: 15px 18px 15px 18px;
    left: 0;
    right: 0;
    pointer-events: none;
    opacity: 0;
    transform: translateY(50px);
    transition:
      opacity 300ms $easeInOut3,
      transform 300ms $easeInOut3;

    span {
      padding-bottom: 10px;
      display: block;
      text-transform: capitalize;
    }

    .info-heading {
      padding-bottom: 3px;
      font-size: 14px;
      line-height: 1.2em;
    }

    .info-sub {
      font-size: 13px;
      color: $fontColorTwo;
    }

    &.active {
      transform: translateY(0px);
      opacity: 1;
      pointer-events: auto;
    }
  }

  &:hover {
    .more-info-button {
      opacity: 1;
    }
  }
}

.quick-link-content-blocked {
  border-radius: $radius;
  overflow: hidden;
  flex: 0 1 calc((100% / 6) - 17px);
  display: block;
  position: relative;
  box-sizing: border-box;
  aspect-ratio: $card-ar;
  transition:
    transform 300ms $easeInOut3,
    box-shadow 300ms $easeInOut3;
  position: relative;

  img {
    opacity: 1;
  }

  &:hover {
    transform: translate(0px, -3px);
    cursor: pointer;
  }

  .slot-image {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    opacity: 1;
    z-index: 1;
    position: absolute;
    height: 100%;
    width: 100%;
    background: linear-gradient(
      149deg,
      rgba(13, 34, 52, 0.85) -1.28%,
      rgba(4, 13, 21, 0.85) 100%
    );
    transition:
      transform 300ms $easeInOut3,
      box-shadow 300ms $easeInOut3,
      opacity 200ms $easeInOut3;

    p {
      font-size: 20px;
      font-style: normal;
      font-weight: 700;
      line-height: 24px;
      display: -webkit-box;
      /* max-width: 90%; */
      -webkit-line-clamp: 3;
      line-clamp: 3;
      -webkit-box-orient: vertical;
      overflow: hidden;
      text-align: center;
      opacity: 0;
      padding: 0px 10px;
      position: absolute;
      margin-top: 20px;

      &.mobile-three {
        font-size: 16px;
      }

      @media screen and (max-width: 416px) {
        font-size: 18px;
      }
    }

    span {
      font-size: 13px;
      font-style: normal;
      // font-weight: 500;
      line-height: normal;
      position: absolute;
      bottom: 20px;
      opacity: 0;
    }

    svg {
      opacity: 0;

      @extend .svg-center;
    }
  }

  .inhouse-game-icon {
    aspect-ratio: $card-ar;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    opacity: 1;
    z-index: 2;
    position: absolute;
    height: 100%;
    width: 100%;
    background: linear-gradient(
      149deg,
      rgba(13, 34, 52, 0.85) -1.28%,
      rgba(4, 13, 21, 0.85) 100%
    );

    p {
      font-size: 20px;
      font-style: normal;
      font-weight: 700;
      line-height: 24px;
      display: -webkit-box;
      /* max-width: 90%; */
      -webkit-line-clamp: 3;
      line-clamp: 3;
      -webkit-box-orient: vertical;
      overflow: hidden;
      text-align: center;
      opacity: 0;
      padding: 0px 10px;
      position: absolute;
      margin-top: 20px;

      @media screen and (max-width: 416px) {
        font-size: 18px;
      }
    }

    span {
      font-size: 13px;
      font-style: normal;
      // font-weight: 500;
      line-height: normal;
      position: absolute;
      bottom: 20px;
      opacity: 0;
    }

    svg {
      opacity: 0;

      @extend .svg-center;
    }

    &:hover {
      background: linear-gradient(
        149deg,
        rgba(24, 56, 84, 0.85) -1.28%,
        rgba(10, 30, 48, 0.85) 100%
      );

      p {
        opacity: 1;
      }

      span {
        opacity: 1;
      }

      svg {
        opacity: 1;
      }
    }
  }

  // .game-details-display {
  //   // padding: 15px 18px 15px 18px;
  //   padding: 9px 18px 15px 18px;
  //   position: relative;
  // }

  .more-info-button {
    position: absolute;
    right: 10px;
    top: 10px;
    transition: opacity 500ms $easeInOut3;
    opacity: 1;

    svg {
      fill: $fontColorTwo;
      // transform: rotate(180deg);
      color: $fontColorTwo;

      path {
        color: $fontColorTwo;
      }
    }
  }

  .more-info-content {
    // display: none;
    background-color: $secondaryBackgroundColor;
    height: 100px;
    position: absolute;
    bottom: 0;
    padding: 15px 18px 15px 18px;
    left: 0;
    right: 0;
    pointer-events: none;
    opacity: 0;
    transform: translateY(50px);
    transition:
      opacity 300ms $easeInOut3,
      transform 300ms $easeInOut3;

    span {
      padding-bottom: 10px;
      display: block;
      text-transform: capitalize;
    }

    .info-heading {
      padding-bottom: 3px;
      font-size: 14px;
      line-height: 1.2em;
    }

    .info-sub {
      font-size: 13px;
      color: $fontColorTwo;
    }

    &.active {
      transform: translateY(0px);
      opacity: 1;
      pointer-events: auto;
    }
  }

  background-color: #151a33;

  &:hover {
    .more-info-button {
      opacity: 1;
      transition: none;
    }
  }
}

.search-area {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  border-radius: 6px;
  border: 1px solid rgba(232, 229, 255, 0.05);
  background: linear-gradient(180deg, #171c36 54%, rgba(23, 28, 54, 0.8) 100%);
  padding: 24px;
  margin-bottom: 28px;

  .row-area {
    display: flex;
    gap: 14px;
    justify-content: space-between;

    .search {
      width: 100%;
    }
  }

  .filter-area {
    width: 100%;
    display: flex;
    gap: 14px;
  }

  &.mobile-three {
    flex-direction: column;

    .filter-area {
      width: 100%;
      flex-direction: column;
      gap: 14px;
    }
  }

  @media screen and (max-width: 769px) {
    flex-direction: column;

    .filter-area {
      width: 100%;
      flex-direction: column;
      gap: 14px;
    }
  }
}

.no-found-message {
  color: #fff;
  line-height: 1.55em;
  letter-spacing: 0.01em;
}

@keyframes pulsate {
  0%,
  100% {
    background-position: 0% 100%;
  }

  50% {
    background-position: 100% 0;
  }
}

.loading-card,
.loading-card-mini {
  border-radius: $radius;
  width: 100%;
  aspect-ratio: $card-ar;
  background: linear-gradient(
    90deg,
    rgba(21, 26, 51, 0.8) 25%,
    rgba(33, 38, 63, 1) 50%,
    rgba(21, 26, 51, 0.8) 75%
  );
  background-size: 200% 200%;
  animation: pulsate 2s ease-in-out infinite;
}

.loading-card-mini {
  aspect-ratio: 1.59/1;
}

.gradient-overlay {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  bottom: 23%;
  z-index: 1;

  // border: 1px solid #fff;
  background: linear-gradient(0deg, #151a33 5%, rgba(21, 26, 51, 0) 100%);
}

.game-details-display {
  position: absolute;
  right: 0;
  left: 0;
  bottom: 0;
  z-index: 2;
  height: 100%;
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: center;

  .region-blocked-text {
    font-size: 13px;
    font-weight: 600;
    line-height: 18px;
    color: #e8f5ff;
    padding: 12px 6px 9px 6px;

    @media (max-width: 768px) {
      font-size: 12px;
      line-height: 14px;
    }

    @media (max-width: 480px) {
      font-size: 10px;
      line-height: 10px;
    }
  }

  .region-blocked-overlay {
    svg {
      width: 35px;
      height: 35px;

      @media (max-width: 768px) {
        width: 26px;
        height: 26px;
      }

      @media (max-width: 480px) {
        width: 20px;
        height: 20px;
      }
    }
  }

  .h3 {
    line-height: 1.2em;
    padding-bottom: 8px;
    font-size: 14px;
    overflow: hidden;
    font-weight: 700;
    // white-space: nowrap;
    -webkit-line-clamp: 3;
    line-clamp: 3;
    -webkit-box-orient: vertical;
    text-overflow: ellipsis;

    @media (max-width: $breakPointThree) {
      font-size: 14px;
    }

    @media (max-width: 400px) {
      font-size: 13px;
    }
  }

  .sub-text {
    // overflow: hidden;
    // white-space: nowrap;
    // text-overflow: ellipsis;
    font-size: 11px;
    color: #fff;

    @media (max-width: 400px) {
      font-size: 11px;
    }
  }
}

.providers-page-container {
  padding-top: 25px;

  .providers-page-input-container {
    margin: 20px 0px;
  }

  .providers-list {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    gap: 10px;
    margin-top: 20px;

    &.mobile-two {
      grid-template-columns: repeat(2, 1fr);
    }

    &.mobile-three {
      grid-template-columns: repeat(1, 1fr);
    }
  }
}

.provider-games-container {
  padding-top: 25px;

  .filter-area {
    max-width: 300px;
  }
}

@media (max-width: $breakpoint-one) {
}

@media (prefers-color-scheme: dark) {
}

.row-area {
  display: flex;
  gap: 14px;
  justify-content: space-between;

  .search {
    width: 100%;
  }
}

@media (max-width: 1280px) {
  .row-area {
    display: flex;
    flex-direction: column;
    gap: 14px;
    justify-content: space-between;

    .search {
      width: 100%;
    }
  }
}

.slot-image-container {
  position: relative;
  width: 100%;
  height: 100%;
  color: #fff;
  img {
    object-fit: cover;
    height: auto;
    border-radius: 8px;
  }
}

.slot-image-overlay {
  height: 29px;
  flex-shrink: 0;
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  background: linear-gradient(
    180deg,
    rgba(0, 0, 0, 0) 0%,
    rgba(0, 0, 0, 0.5) 38.5%,
    rgba(0, 0, 0, 0.7) 100%
  );
  text-align: center;

  span {
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    padding-bottom: 4px;
    color: #fff;
    text-shadow: 0px 2px 4px rgba(0, 0, 0, 0.25);
    font-size: 9px;
    font-style: normal;
    line-height: 100%; /* 10px */
    letter-spacing: 0.5px;
    font-family: var(--oswald-font);
    text-transform: uppercase;
    font-weight: 400;

    @media (max-width: 768px) {
      font-size: 7.5px;
      padding-bottom: 3px;
    }
  }

  @media (max-width: 768px) {
    height: 22px;
  }
}
