/* variables.module.scss */
// This file is a mess

$card-ar: 152 / 201;

$primary-color: #000;

$dark-one: #14172e;
$dark-two: #262226;

$primary-fill-color: #0077db;
$primary-stroke-color: #2099ff;
$secondary-fill-color: #64b425;
$secondary-stroke-color: #7dd934;
$inputLabelColorLight: #e8e5ff;
$inputLabelColor: #e8e5ff;
$sub-heading-color: #e8e5ff;
$btn-fill-active-color: #191f3b;
$btn-fill-hover-color: #191f3b;
$btn-fill-color: #262c52;
$btn-stroke-color: #282f58;
$btn-active-border-color: #1d2242;
$btn-hover-state-color: #23294d;
$font-color-two: #5e679e;
$inputLabelColor: #3e4675;
$fontBlueGradient: linear-gradient(90deg, #48d3ff 34%, #40b5da 100%);

$blue: #0077db;
$blue-highlight: $primary-stroke-color;
$white: #ebebeb;
$yellow: #a7862c;
$green: $secondary-fill-color;
$lightGreen: #8efe36;

$lightGold: #ffd076;
$darkGold: #ffa333;
$gold: #ffbe18;
$orange: #ff9254;
$diceRed: #ff5959;
$darkPurple: #8a2eff;
$pink: #ff42f7;
$diamond: #50f2fe;
$platinum: #dcdcdc;
$bronze: #d88f46;

$tranTime: 200ms;

$purple-one: #b7b4d2;

$sideBarBackground: #151a33;
$primaryBackgroundColor: #0f1328;
// used for the main content section background
$secondaryBackgroundColor: #191f3b;
$thirdBackground: #13172f;
$fourthBackground: #262c52;
$fifthBackground: #191f3b;
$sixthBackground: #111631;
$eightBackground: #394171;
$ninthBackground: #12172f;

$borderColor: #191f3a;
$inputBorderColor: #252b4e;
$rowColor: #151a33;
$btnFillThree: #171c33;
$btnFill: #1b213e;

$btnBorderTwo: #212643;
$btnFillTwo: #252b4d;
$btnStroke: #282f53;
$btnActiveFill: #121730;
$btnActiveFill: #212749;
$btnSideActiveFill: #212749;

$editBackground: #171b34;

$rewardBackground: #191e38;
$scrollBarColor: #242a4b;

$primaryFontColor: #e8e5ff;
$font2: #b2b1c9;
$secondaryFontColor: #b7b4d2;
$lightBackgroundColor: #212744;
$darkLineColor: #1b203b;
$inputBorder: $btnStroke;
$activeBtn: #13172d;

$rouletteRed: #d92b50;
$rouletteGreen: #72d76c;
$rouletteBlack: #4e537b;

$diceBackground: #313642;
$diceNegativeColor: $diceRed;
$popupRed: #892f2f;
$failRed: #ff5858;
$successGreen: #00e075;

$fontSizeOne: 22px;
$fontSizeTwo: 18px;
$fontSizeThree: 15px;
$fontSizeFour: 13px;

$fontColor: #fff;

$fontColorTwo: #7179a5;
$fontColorThree: #5e678e;
$fontColorFour: #abb0c5;
$fontColorFive: #5e679e;
$fontColorSix: #3e4675;
$fontColorSeven: #5b628c;

$breakpoint-one: 990px;
$Dmw: 30px;

$mainFont: var(--main-font);
$boldFont: $mainFont;
$primaryFont: $mainFont;
$primaryFontBold: $mainFont;
$primaryFontMedium: $mainFont;
$radius: 6px;

$borderRadius: 16px;

$modalWidth: 380px;
$padding: 20px;
$mobile-padding: 14px;

$easeInOut3: ease-in-out;

$modalBreakPointOne: 500px;

$breakPointOne: 1100px;
$breakPointTwo: 768px;
$breakPointThree: 420px;
$breakPointFour: 391px;

// Colors
$alertConnectionLost: rgba(255, 160, 0, 1);
$alertConnectionLostTransparent: rgba(255, 160, 0, 0.5);
$alertFailed: rgba(209, 64, 63, 1);
$alertFailedTransparent: rgba(209, 64, 63, 0.5);
$alertFontColor: rgba(232, 229, 255, 1);
$alertGeneric: rgba(109, 114, 136, 1);
$alertGenericTransparent: rgba(109, 114, 136, 0.8);
$alertSuccess: rgba(75, 174, 79, 1);
$alertSuccessTransparent: rgba(75, 174, 79, 0.5);

// Breakpoints
$xs: 540px;
$sm: 720px;
$md: 960px;
$lg: 1360px;
$xl: 1600px;

// Spacing
$spacingXXS: 5px;
$spacingXS: 10px;
$spacingSM: 15px;
$spacingMD: 20px;
$spacingLG: 35px;
$spacingXL: 40px;
$spacingXXL: 45px;
$spacingXXXL: 50px;

// Define your mixin for the gradient
@mixin gradient($angle, $start-color, $end-color) {
  background: linear-gradient($angle, $start-color, $end-color);
}

@mixin scrollbars(
  $size,
  $foreground-color,
  $background-color: mix($foreground-color, white, 50%)
) {
  // For Google Chrome
  &::-webkit-scrollbar {
    width: $size;
    height: $size;
  }

  &::-webkit-scrollbar-thumb {
    background: $foreground-color;
    border-radius: $radius;
  }

  &::-webkit-scrollbar-track {
    background: $background-color;
  }

  // For Internet Explorer
  & {
    scrollbar-face-color: $foreground-color;
    scrollbar-track-color: $background-color;
  }
}

.golden-text {
  background: linear-gradient(181.17deg, #ffd999 14.35%, #e49d26 99%);
  background-clip: text;
  -webkit-text-fill-color: transparent;
}

@mixin full-width {
  width: calc(100% + 35px);
  margin-left: -20px;
  border-radius: 0px;
}
